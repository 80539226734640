<template>
  <el-dialog width="60%"
    title="协商记录"
    :visible.sync="dialogVisible"
    custom-class="caluse-dialog">
    <div class="form-box yl-table-box">
      <el-button type="primary" icon="el-icon-refresh" size="small" @click="getList"></el-button>
      <el-button type="primary" size="small" @click="isNegotiation = true">协商</el-button>
    </div>
    <el-table
    ref="singleTable"
    :data="tableData"
    @current-change="handleCurrentChangeRow"
    style="width: 100%">
    <el-table-column
      property="userName"
      label="姓名">
    </el-table-column>
    <el-table-column
      property="roleName"
      label="角色">
    </el-table-column>
    <el-table-column
      property="createTime"
      label="时间">
    </el-table-column>
    <el-table-column
      property="advice"
      label="意见">
    </el-table-column>
    <el-table-column
      property="describe"
      label="说明">
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.current"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="formData.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">返 回</el-button>
    </span>
    <negotiationDialog v-model="isNegotiation"
      @submit="handleSubmitNegotiation" />
  </el-dialog>
</template>
<script>
import { policyBindUser, enquiryBindUser } from "@/api/policy";
import { pageNegotiation, addNegotiation } from "@/api/claimData";
import negotiationDialog from "@/views/AuditManagement/components/negotiationDialog.vue";
export default {
  props: {
    showDialog: Boolean,
    editData: {
      type: Object,
      default: ()=> {}
    },
    apiType: {
      type: String,
      default: ''
    },
    approvalType: {
      type: [String, Number],
      default: ''
    }
  },
  components: { negotiationDialog },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }else {
          this.$emit('change', true);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.currentRow = '';
         this.formData.size = 10;
         this.formData.current = 1;
          this.getList()
        }else {
          this.dialogVisible = false;
        }
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      options: [],
      value: '',
      tableData: [],
      currentRow: '',
      formData: {
         current: 1,
         size: 10
      },
      total: 0,
      timeObj: null,
      isNegotiation: false
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
      getList() {
         pageNegotiation({...this.formData, processId: this.editData.id, processType: this.approvalType}).then(res=>{
            this.tableData = res.data.records;
            this.total = res.data.total
         }).catch(err=>{
            console.log(err);
         })
      },
      handleCurrentChangeRow(val) {
         console.log(val);
        this.currentRow = val;
      },
      handleSizeChange(val) {
        this.formData.size = val;
        this.formData.current = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.formData.current = val;
        this.getList();
      },
      handleInput() {
         clearTimeout(this.timeObj);
         this.timeObj = setTimeout(()=>{
         clearTimeout(this.timeObj);
            this.formData.current = 1;
            this.getList()
         }, 500)
      },
      handleSubmit() {
         if (!this.currentRow) {
            this.$message.error('请选择客户');
            return false;
         }
         if (this.apiType == 'policy') {
            policyBindUser({
               customerUid: this.currentRow.userId,
               policyIds: this.ids
            }).then(res=>{
               this.$message.success('分配成功');
               this.dialogVisible = false;
            }).catch(err=>{
               this.$message.error('分配失败');
            })
         }else if (this.apiType == 'enquiry'){
            enquiryBindUser({
               customerUid: this.currentRow.userId,
               enquireIds: this.ids
            }).then(res=>{
               this.$message.success('分配成功');
               this.dialogVisible = false;
            }).catch(err=>{
               this.$message.error('分配失败');
            })
         }else {
            this.$emit('submit', this.currentRow)
         }
      },
      handleReset() {
        this.formData.size = 10;
        this.formData.current = 1;
        this.formData.fastLike = '';
        this.currentRow = '';
        this.getList();
      },
    handleSubmitNegotiation(e) {
      addNegotiation({
        ...e,
        caseId: this.editData.caseId,
        processType: this.approvalType
      }).then(res => {
        this.isNegotiation = false;
        this.getList();
      }).catch(err => {
        console.log(err);
      });
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .caluse-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    overflow: auto;
    padding: 24px;
  }
}
.form-box {
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px;
}
</style>
<style lang="scss">
.policy-label-style {
  font-size: 14px;
  color: #333333;
  margin-bottom: 12px;
}
.yl-table-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  ::v-deep .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .el-table__cell {
      background-color: #f0f4fb;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
}
</style>
